exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lunchtime-jsx": () => import("./../../../src/pages/lunchtime.jsx" /* webpackChunkName: "component---src-pages-lunchtime-jsx" */),
  "component---src-pages-travels-jsx": () => import("./../../../src/pages/travels.jsx" /* webpackChunkName: "component---src-pages-travels-jsx" */),
  "component---src-pages-travels-spain-jsx": () => import("./../../../src/pages/travels/spain.jsx" /* webpackChunkName: "component---src-pages-travels-spain-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

